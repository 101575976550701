<template>
  <div class="ma-6">
    <v-card v-if="ftClient">
      <v-card-title>
        <span class="display-1">
          Cliente <span class="font-weight-bold font-italic">{{ ftClient.businessName }}</span>
          {{infoTabCmp}}
        </span>
      </v-card-title>
      <v-tabs
          v-model="tab"
          background-color="accent-4"
          center-active
          align-with-title
          grow
      >
        <v-tab>Information</v-tab>
        <v-tab>Users</v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <client-information-cmp v-bind="infoTabCmp" :ft-client-id="ftClientId"/>
          </v-tab-item>
          <v-tab-item>
            <client-organisation-users-cmp :ft-client-id="ftClientId" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ClientUsers from '@/views/administration/FoodyTekClients/FTClientDetails/tabs/ClientUsers.vue'
import ClientInformationCmp from "@/components/commons/ClientInformationCmp/ClientInformationCmp.vue";
import ClientOrganisationUsersCmp from "@/components/commons/ClientOrganisationUsersCmp.vue";

export default {
  name: 'FTClientDetails',
  components: { ClientOrganisationUsersCmp, ClientInformationCmp },
  data () {
    return {
      ftClientId: this.$route.params.ftClientId,
      ftClient: null,
      tab: 0,
      infoTabCmp: null
    }
  },
  mounted () {
    this.loadInfoLite()
  },
  methods: {
    async loadInfoLite () {
      const res = await this.$http.auth.get(`ft-clients/${this.ftClientId}/lite`)
      this.ftClient = res.data
    }
  }
}
</script>

<style scoped>

</style>

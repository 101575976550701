import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// IMPORT MODULES
import account from './modules/account'
import appSettings from '@/store/modules/appSettings'
import clientStatus from "@/store/modules/clientStatus";

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['account', 'appSettings']
    })
  ],
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    account,
    appSettings,
    clientStatus
  }
})

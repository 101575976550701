import Vue from "vue";

const state = {
  stripeEnabled: null,
  requireCustomerRegistration: null,
  isAcceptingPayments: null,
  onboardingComplete: null,
}

const getters = {
  stripeEnabled: (state) => {
    return state.stripeEnabled
  },
  requireCustomerRegistration: (state) => {
    return state.requireCustomerRegistration
  },
  isAcceptingPayments: (state) => {
    return state.isAcceptingPayments
  },
  onboardingComplete: (state) => {
    return state.onboardingComplete
  }
}

const mutations = {
  setClientStatus (state, newStatus) {
    Object.assign(state, newStatus)
  }
}

const actions = {
  async updateClientStatus ({ commit }) {
    try {
      const res = await Vue.prototype.$http.payment.get('ft-clients/status')
      commit('setClientStatus', res.data)
    } catch (e) {
    }
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}

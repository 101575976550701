<template>
  <div>
    <ft-from-to-select :fetch-data="loadDetails"/>

    <!-- Purchases -->
    <div v-if="stats">
      <v-row class="mt-4">
        <v-col cols="12">
          <v-card class="pa-3" outlined>
            <v-card-title class="headline">
              Totale acquisti:
              {{ formatMoney(totalPurchases) }}
            </v-card-title>
            <v-card-text>
              <v-row class="my-1">
                <v-col cols="12" sm="6">
                  <amount-progress-circular
                      text="Con app Apollo"
                      color="primary"
                      :amount="stats.purchases"
                      :total="totalPurchases"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <amount-progress-circular
                      text="Senza app Apollo"
                      color="secondary"
                      :amount="stats.legacy"
                      :total="totalPurchases"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Top ups -->
      <v-row>
        <v-col cols="12">
          <v-card class="pa-3" outlined>
            <v-card-title class="headline">
              Totale caricato su Apollo: {{ formatMoney(stats.cashTopUps) }}
            </v-card-title>
            <v-card-text>
              <v-row class="my-1">
                <v-col cols="12">
                  <amount-progress-circular
                      text="Cash"
                      color="secondary"
                      :amount="stats.cashTopUps"
                      :total="stats.cashTopUps"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AmountProgressCircular from "@/components/UIAccessories/AmountProgressCircular.vue";
import FtFromToSelect from "@/components/UIAccessories/FtFromToSelect.vue";

export default {
  name: 'DeviceStatsTab',
  components: { FtFromToSelect, AmountProgressCircular },
  props: {
    serialNumber: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      stats: null,
    }
  },
  computed: {
    ...mapGetters([ 'isAdmin', 'isAdminLoggedAsClient' ]),
    totalPurchases () {
      return this.stats.purchases + this.stats.legacy;
    },
  },

  methods: {
    async reload (load) {
      setTimeout(load, 800)
    },
    async loadDetails ({ from, to }) {
      const res = await this.$http.reports.get(`v2/dashboard/devices/${this.serialNumber}/stats`, {
        params: {
          from, to
        }
      })
      this.stats = res.data
    },
  }
}
</script>

<style scoped>

</style>

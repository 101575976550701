<script>
import ClientOrganisationUsersCmp from "@/components/commons/ClientOrganisationUsersCmp.vue";

export default {
  name: 'OrganisationUsers',
  components: { ClientOrganisationUsersCmp },
}
</script>

<template>
  <div>
    <h1>{{ $t('Organisation users') }}</h1>

    <client-organisation-users-cmp :ft-client-id="$store.getters.clientId" />
  </div>
</template>

<template src="./AccountInfo.html"/>

<script>
import ClientInformationCmp from "@/components/commons/ClientInformationCmp/ClientInformationCmp.vue";

export default {
  name: 'AccountInfo',
  components: { ClientInformationCmp },
}
</script>

<script>
export default {
  name: 'AmountProgressCircular',
  props: {
    amount: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    count: Number,
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    percent () {
      if (!this.total) return 0
      return Math.round(this.amount / this.total * 10000) / 100;
    },
    showCount () {
      return this.count !== undefined;
    }
  }
}
</script>

<template>
  <div class="text-center">
    <v-progress-circular :value="percent" :size="100" :color="color" rotate="-90" width="15">
      {{ percent }}%
    </v-progress-circular>
    <div class="mt-2">
      <div class="font-weight-bold text-h6 my-1 mb-0">
        {{ formatMoney(amount) }}
      </div>
      <div v-if="showCount">{{ count }} acquisti</div>
      <span class="body-1">{{ text }}</span>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
export default {
  name: "ClientTypeSetting",
  data () {
    return {
      clientTypes: [],
      type: null,
      loading: false,
    };
  },
  props: {
    ftClientId: {
      type: String,
      required: true,
    },
  },
  mounted () {
    this.getClientTypes()
    this.getType()
  },
  methods: {
    async getClientTypes () {
      try {
        this.loading = true;
        const res = await this.$http.auth.get('ft-clients/types');
        this.clientTypes = res.data;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async getType () {
      try {
        this.loading = true;
        const res = await this.$http.auth.get(`ft-clients/${this.ftClientId}/type`);
        this.type = res.data;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async updateType() {
      try {
        this.loading = true;
        await this.$http.auth.put(`ft-clients/${this.ftClientId}/type`, {
          type: this.type,
        });
        this.$dialog.notify.success("Client type updated successfully");
      } catch (e) {
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>

<template>
  <div>
    <h3 class="mb-5">Client type</h3>

    <v-row align="center">
      <v-col cols="auto">
        <v-select
            v-model="type"
            :items="clientTypes"
            label="Client type"
            outlined
            hide-details
            dense
            style="max-width: 15rem"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn
            @click="updateType"
            :loading="loading"
            color="primary"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "RemoteTopUpsTable",
  props: {
    topUps: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      topUpsHeaders: [
        { text: this.$t('Date and time'), value: 'created' },
        { text: this.$t('Status'), value: 'status' },
        { text: this.$t('Top up per user'), value: 'amountPerUser' },
        { text: this.$t('Total top ups'), value: 'totalAmount' },
      ].map(i => ({ ...i, sortable: false })),
    }
  },
  computed: {
    items () {
      return this.topUps.map(item => ({
        ...item,
        created: this.formatDateNoSecs(item.created),
        amountPerUser: this.formatMoney(item.amountPerUser),
        totalAmount: this.formatMoney(item.totalAmount),
      }))
    }
  },
  methods: {
    rowClick (item) {
      this.$router.push({
        name: 'top-up-details',
        params: {
          topUpId: item.id
        }
      })
    },
  }
}
</script>

<template>
  <v-data-table
      :headers="topUpsHeaders"
      :items="items"
      class="row-pointer"
      @click:row="rowClick"
  >
    <template v-slot:item.status="{ item }">
      <boolean-icon v-if="item.completed" :value="item.completed"/>
      <span v-else>
        {{ item.transactionsCount }} / {{ item.usersCount }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import PriceInput from "@/components/fields/PriceInput.vue";

export default {
  name: "PlatformFeeForm",
  components: { PriceInput },
  props: {
    ftClientId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      form: {
        platformFixedFee: null,
        platformPercentFee: null,
      },
      rules: {
        required: value => !!value || "Required.",
        positive: value => (value >= 0 && !isNaN(value)) || "Must be a positive number.",
        percentage: value =>
            (value >= 0 && value <= 100 && !isNaN(value)) || "Must be between 0 and 100.",
      },
      loading: false,
    };
  },
  mounted () {
    this.getPlatformFee();
  },
  methods: {
    async getPlatformFee () {
      try {
        this.loading = true
        const res = await this.$http.payment.get("platform-fees/" + this.ftClientId);
        this.form = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    resetToDefault () {
      this.form.platformFixedFee = null
      this.form.platformPercentFee = null
      this.savePlatformFee()
    },
    async savePlatformFee () {
      try {
        this.loading = true;
        await this.$http.payment.put("platform-fees/" + this.ftClientId, {
          platformFixedFee: this.form.platformFixedFee,
          platformPercentFee: this.form.platformPercentFee,
        });
        this.$dialog.notify.success("Platform fee saved successfully");
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<template>
  <div>
    <h3 class="mb-5">Platform Fees</h3>
    <div class="d-flex">
    <price-input
        v-model="form.platformFixedFee"
        style="max-width: 20rem"
        label="Platform Fixed Fee"
        type="number"
        outlined dense
    />
    <v-text-field
        v-model="form.platformPercentFee"
        class="ml-4"
        style="max-width: 16rem"
        label="Platform Percentage Fee"
        type="number"
        suffix="%"
        outlined dense
    />
    </div>
    <v-btn
        color="primary"
        @click="savePlatformFee"
        :disabled="loading"
        :loading="loading"
    >Save Fees</v-btn>
    <v-btn
        color="warning darken-1"
        class="ml-3"
        outlined
        @click="resetToDefault"
        :disabled="loading"
        :loading="loading"
    >Reset fees to default</v-btn>

    <div class="text-caption mt-2">
      The fixed fee has to be set euros. For example, 0.25€ should be written as is (=0.25).
      <br>
      The percentage fee has to be set as a percentage. For example, 3% should be written as is (=3).
    </div>
  </div>
</template>

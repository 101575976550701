var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show )?_c('action-modal',{attrs:{"data":_vm.data,"edit-mode":false,"submit-btn-text":_vm.$t('Allow entrance'),"cancel-btn-text":_vm.$t('Cancel'),"loading-text":_vm.$t('Loading...'),"submit-title":_vm.$t('Allow {name} a day entrance to driving range', { name: _vm.user.name }),"submit-success-message":_vm.$t('User can now play at the driving range'),"fields":_vm.fields,"field-default-options":_vm.fieldDefaultOptions,"submit-promise-callback":_vm.create},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"field.requirementId",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('v-select',{staticClass:"hide-input",class:classes,attrs:{"label":label,"outlined":"","item-value":"id","items":_vm.entranceFees,"error-messages":errors},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" "),(item.feeAmount)?_c('span',[_vm._v(" - "),_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.formatMoney(item.feeAmount))}})]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.devices ? item.devices.map(function (i) { return i.serialNumber; }).join(', ') : _vm.$t("It doesn't apply to any device"))+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" "),(item.feeAmount)?_c('span',[_vm._v(" - "),_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.formatMoney(item.feeAmount))}})]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.devices ? item.devices.map(function (i) { return i.serialNumber; }).join(', ') : _vm.$t("It doesn't apply to any device"))+" ")])],1)]}}],null,true),model:{value:(_vm.data.requirementId),callback:function ($$v) {_vm.$set(_vm.data, "requirementId", $$v)},expression:"data.requirementId"}})]}}],null,false,88239236),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="active-devices" class="">
    <h1>{{ $t("Users' wallets") }}</h1>

    <user-virtual-keys-table :ft-client-id="$store.getters.clientId"/>
  </div>
</template>

<script>
import UserVirtualKeysTable from "@/components/UserVirtualKeysTable.vue";

export default {
  name: 'UserWallet',
  components: { UserVirtualKeysTable }
}
</script>

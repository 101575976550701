<script>
export default {
  name: 'FtCardLayout',
  props: {
    title: {
      type: String,
      required: true
    },
    noTextContent: {
      type: Boolean,
      default: false
    },
    enableEditMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editMode: false
    }
  },
  created () {
    this.$on('updateEditMode', this.handleUpdateEditMode)
  },
  mounted () {
  },
  methods: {
    updateEditMode (enabled) {
      this.$emit('updateEditMode', enabled)
    },
    handleUpdateEditMode (enabled) {
      this.editMode = enabled
    },
    async cancelBtn () {
      this.updateEditMode(false)
      this.$emit('reload')
    },
    async saveBtn () {
      const valid = await this.$refs.observer.validate()
      if (!valid) return

      await this.$emit('save')
      this.updateEditMode(false)
      setTimeout(() => {
        this.$emit('reload')
      }, 800)
    }
  }
}
</script>

<template>
  <v-card outlined class="my-5">
    <v-card-title class="headline">
      {{ title }}
      <v-divider class="mx-4" inset vertical/>
      <v-spacer></v-spacer>
      <slot name="actions">
        <div v-if="enableEditMode">
          <v-btn v-if="!editMode"
                 color="warning"
                 fab small elevation="1"
                 @click="updateEditMode(true)">
            <v-icon small>fa-edit</v-icon>
          </v-btn>
        </div>
      </slot>
    </v-card-title>
    <validation-observer ref="observer">
      <slot v-if="noTextContent"/>
      <v-card-text v-else>
        <slot/>
      </v-card-text>
    </validation-observer>
    <v-card-actions class="pr-6 pb-3" v-if="enableEditMode && editMode">
      <v-spacer/>
      <v-btn color="grey" dark class="mr-2"
             fab small elevation="1" outlined
             @click="cancelBtn">
        <v-icon>fa-close</v-icon>
      </v-btn>
      <v-btn color="success" dark
             fab small elevation="1"
             @click="saveBtn">
        <v-icon>fa-check</v-icon>
      </v-btn>
    </v-card-actions>
    <!--    <div v-if="enableEditMode">-->
    <!--      <v-fab-transition v-if="!editMode">-->
    <!--        <v-btn-->
    <!--            color="orange"-->
    <!--            dark-->
    <!--            absolute-->
    <!--            top-->
    <!--            right-->
    <!--            fab-->
    <!--            small-->
    <!--            style="margin-top: 2rem"-->
    <!--            @click="updateEditMode(true)">-->
    <!--          <v-icon small>fa-edit</v-icon>-->
    <!--        </v-btn>-->
    <!--      </v-fab-transition>-->
    <!--    </div>-->
  </v-card>
</template>

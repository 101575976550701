var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('In app card transactions')))]),_c('ft-from-to-select',{attrs:{"fetch-data":_vm.loadData}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"item-key":"to"},scopedSlots:_vm._u([{key:"item.datetime",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateNoSecs(value))+" ")]}},{key:"item.user.email",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-chip',{attrs:{"outlined":""},on:{"click":function($event){return _vm.openTransactions(item)}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatMoney(value))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
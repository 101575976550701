<script>
import RemoteTopUpListTable from "@/views/client_customers/history/RemoteTopUpsTable.vue";
import PastSepaPaymentsTable from "@/components/PastSepaPaymentsTable.vue";

export default {
  name: "PastTopUps",
  components: { PastSepaPaymentsTable, RemoteTopUpListTable },
  data () {
    return {
      monthTopUps: [],
    }
  },
  mounted () {
    this.fetchTopUps()
  },
  methods: {
    async fetchTopUps () {
      const response = await this.$http.payment.get('/remote-top-ups')
      this.monthTopUps = response.data
    },
  },
}
</script>

<template>
  <div>
    <h1>{{ $t('Wallet remote top-ups history') }}</h1>

    <v-card class="my-6" outlined>
      <v-card-title>{{ $t('Current month top-ups (they will be debited the first days of the next month)') }}</v-card-title>

      <v-card-text>
        {{ $t('Total charges of the month up to now') }}: {{ formatMoney(monthTopUps.reduce((acc, item) => acc + item.totalAmount, 0)) }}
      </v-card-text>

      <remote-top-up-list-table :topUps="monthTopUps"/>
    </v-card>

    <v-card class="my-6" outlined>
      <v-card-title>{{ $t('Past SEPA debits') }}</v-card-title>

      <past-sepa-payments-table />
    </v-card>
  </div>
</template>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('action-modal',{attrs:{"data":_vm.obj,"cancel-btn-text":_vm.$t('Cancel'),"loading-text":_vm.$t('Loading...'),"submit-title":_vm.$t('Invite new user to the platform'),"submit-success-message":_vm.$t('Invite email sent successfully!'),"submit-btn-text":_vm.$t('Send link'),"fields":_vm.fields,"submit-promise-callback":_vm.submit},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"field.clientType",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors,"items":_vm.clientTypes},model:{value:(_vm.obj.clientType),callback:function ($$v) {_vm.$set(_vm.obj, "clientType", $$v)},expression:"obj.clientType"}})]}},{key:"field.role",fn:function(ref){
var errors = ref.errors;
var label = ref.label;
var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors,"items":_vm.roleTypes},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.description)}})],1)]}}],null,true),model:{value:(_vm.obj.role),callback:function ($$v) {_vm.$set(_vm.obj, "role", $$v)},expression:"obj.role"}})]}}]),model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}})}
var staticRenderFns = []

export { render, staticRenderFns }
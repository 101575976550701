export default {
  data () {
    return {
      queryAutoUpdateNotInitialised: true,
    }
  },
  beforeMount () {
    const q = this.$route.query.q
    if (q && typeof q === 'string') {
      this.query = JSON.parse(q)
    }
    delete this.queryAutoUpdateNotInitialised
  },
  watch: {
    query: {
      handler: function (val) {
        if (this.queryAutoUpdateNotInitialised) return

        if (val && this.$route.query.q) {
          const q = JSON.parse(this.$route.query.q)

          let changed = false
          for (const key in val) {
            if (val[ key ] !== q[ key ]) {
              changed = true
              break
            }
          }
          if (!changed) return
        }

        this.$router.replace({ query: { q: JSON.stringify(this.query) } })
      },
      deep: true,
      immediate: true
    }
  }
}

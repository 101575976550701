import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from '@/store/store'
import {beforeEnter, beforeEnterCatchDefault, requireRoles, Role} from "@/router/routes.utils"

import LocationsList from '../views/business/devices/LocationsList.vue'
import Login from '../views/Login'
import DevicesStock from '@/views/business/devices/DevicesNotActive.vue'
import PartnersList from '@/views/business/devices/PartnersList.vue'
import AccountInfo from '@/views/business/your_account/AccountInfo/AccountInfo'
import DevicesTransactions from '../views/business/devices/DevicesTransactions.vue'
import UsersVirtualKeys from '@/views/business/users/UserVirtualKeys.vue'
import TestLocalPage from '@/views/TestLocalPage'
import DeviceDetails from '@/views/commons/DeviceDetails/DeviceDetails'
import DevicesInLocationList from '@/views/business/devices/DevicesInLocationList.vue'
import FoodyTekClients from '@/views/administration/FoodyTekClients/FoodyTekClients'
import FTClientDetails from '@/views/administration/FoodyTekClients/FTClientDetails/FTClientDetails'
import Administrators from '@/views/administration/Administrators/Administrators'
import DevicesManagement from '@/views/commons/DevicesManagement/DevicesManagement'
import UsersWallet from '@/views/administration/UsersWallet/UsersWallet'
import UsersTransactions from '@/views/commons/UsersTransactions.vue'
import DeviceUpdates from '@/views/administration/DeviceUpdates/DeviceUpdates'
import UnassignedDevices from '@/views/administration/UnassignedDevices/UnassignedDevices'
import ClientCustomers from '@/views/business/your_account/ClientCustomers.vue'
import RemoteTopUp from "@/views/client_customers/RemoteTopUp.vue";
import CorrispettiviAgenziaEntrate from "@/views/business/accounting/CorrispettiviAgenziaEntrate.vue";
import ClientCustomerInfo from "@/views/client_customers/ClientCustomerInfo.vue";
import clientCustomerCompleteReg from "@/views/client_customers/ClientCustomerCompleteReg.vue";
import PastTopUps from "@/views/client_customers/history/PastTopUps.vue";
import RemoteTopUpListInPayment from "@/views/client_customers/history/RemoteTopUpListInPayment.vue";
import RemoteTopUpDetails from "@/views/client_customers/history/RemoteTopUpDetails.vue";
import UserGroupsManagement from "@/views/business/users/UserGroupsManagement.vue";
import UserGroupDetails from "@/views/business/users/UserGroupDetails.vue";
import Dashboard from "@/views/business/statistics/Dashboard.vue";
import StatsPerUser from "@/views/business/statistics/StatsPerUser.vue";
import RemoteTopUps from "@/views/business/accounting/RemoteTopUps.vue";
import TopUpAndGift from "@/views/business/accounting/TopUpAndGift.vue";
import QrCodeGenerator from "@/views/administration/QrCodeGenerator.vue";
import MonthlyReports from "@/views/business/accounting/MonthlyReports.vue";
import OrganisationUsers from "@/views/business/your_account/OrganisationUsers.vue";
import InAppCardTransactions from "@/views/business/accounting/InAppCardTransactions.vue";

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    beforeEnter: beforeEnterCatchDefault
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    beforeEnter (to, from, next) {
      store.commit('logoutUser')
      next('/login')
    }
  },
  {
    path: '/test-local-page',
    component: TestLocalPage,
    beforeEnter
  },

  // ----------------------- Dashboard -----------------------
  {
    path: '/dashboard',
    component: Dashboard,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/dashboard/per-user',
    component: StatsPerUser,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },

  // ----------------------- Account -----------------------
  {
    path: '/my-account',
    component: AccountInfo,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/client-users',
    component: OrganisationUsers,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/users/client-customers',
    component: ClientCustomers,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },

  // ----------------------- Accounting -----------------------
  {
    path: '/monthly-reports',
    component: MonthlyReports,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN, Role.CLIENT_ACCOUNTANT ])
  },
  {
    path: '/card-transactions',
    component: InAppCardTransactions,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN, Role.CLIENT_ACCOUNTANT ])
  },
  {
    path: '/corrispettivi',
    component: CorrispettiviAgenziaEntrate,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/remote-top-ups',
    component: RemoteTopUps,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/top-up-and-gift',
    component: TopUpAndGift,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },

  // ----------------------- Devices -----------------------
  {
    path: '/partners',
    component: PartnersList,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/partners/:partnerId',
    component: LocationsList,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/locations/:locationId',
    component: DevicesInLocationList,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/devices/not-active',
    component: DevicesStock,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/devices/transactions',
    component: DevicesTransactions,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/devices/unassigned',
    component: UnassignedDevices,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },
  {
    path: '/devices',
    component: DevicesManagement,
    beforeEnter: requireRoles([ Role.ADMIN, Role.CLIENT_ADMIN ])
  },
  {
    path: '/devices/:serialNumber',
    component: DeviceDetails,
    beforeEnter: requireRoles([ Role.ADMIN, Role.CLIENT_ADMIN ])
  },

  // ----------------------- Users -----------------------
  {
    path: '/virtual-keys',
    component: UsersVirtualKeys,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/groups-management',
    component: UserGroupsManagement,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },
  {
    path: '/groups-management/:groupId',
    component: UserGroupDetails,
    beforeEnter: requireRoles([ Role.CLIENT_ADMIN ])
  },

  // ----------------------- Client Customers -----------------------
  {
    path: '/client-customers/complete-registration',
    component: clientCustomerCompleteReg,
    beforeEnter: requireRoles([ Role.CLIENT_CUSTOMER_ADMIN ])
  },
  {
    path: '/client-customers/info',
    component: ClientCustomerInfo,
    beforeEnter: requireRoles([ Role.CLIENT_CUSTOMER_ADMIN ])
  },
  {
    path: '/client-customers/remote-top-up',
    component: RemoteTopUp,
    beforeEnter: requireRoles([ Role.CLIENT_CUSTOMER_ADMIN ])
  },
  {
    path: '/client-customers/past-top-ups',
    component: PastTopUps,
    beforeEnter: requireRoles([ Role.CLIENT_CUSTOMER_ADMIN ])
  },
  {
    path: '/client-customers/sepa-payments/:paymentId',
    name: 'sepa-payments-details',
    component: RemoteTopUpListInPayment,
    beforeEnter: requireRoles([ Role.CLIENT_CUSTOMER_ADMIN ])
  },
  {
    path: '/client-customers/past-top-ups/:topUpId',
    name: 'top-up-details',
    component: RemoteTopUpDetails,
    beforeEnter: requireRoles([ Role.CLIENT_CUSTOMER_ADMIN ])
  },

  // ----------------------- Administration -----------------------
  {
    path: '/ft-clients',
    component: FoodyTekClients,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },
  {
    path: '/ft-clients/:ftClientId',
    component: FTClientDetails,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },
  {
    path: '/administrators',
    component: Administrators,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },
  {
    path: '/users-wallet',
    component: UsersWallet,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },
  {
    path: '/users-wallet/:ftClientId',
    component: UsersWallet,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },
  {
    path: '/users-wallet/:ftClientId/transactions/:userId',
    component: UsersTransactions,
    beforeEnter: requireRoles([ Role.ADMIN, Role.CLIENT_ADMIN ], 'ftClientId')
  },
  {
    path: '/device-updates',
    component: DeviceUpdates,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },
  {
    path: '/qr-code-generator',
    component: QrCodeGenerator,
    beforeEnter: requireRoles([ Role.ADMIN ])
  },

  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

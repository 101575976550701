<template>
  <v-col cols="12" :md="fullWidth ? '12' : '6'">
    <v-row :class="{'mx-1': $vuetify.breakpoint.smAndDown && !$vuetify.breakpoint.sm}">
      <v-col cols="12" sm="5" md="6" lg="5"
             class="text-overline"
             :class="{'pl-5 pr-8 text-right': $vuetify.breakpoint.smAndUp, 'mb-n5 font-weight-bold': $vuetify.breakpoint.smAndDown && !$vuetify.breakpoint.sm}">
        <slot name="label">{{ label }}</slot>
      </v-col>
      <v-col cols="12" sm="7" md="6" lg="7" class="align-self-center">
          <div v-if="editMode && !notEditable">
            <slot name="edit">
              <validation-provider
                  v-slot="{ errors }"
                  :name="label"
                  :rules="rules"
              >
                <slot name="edit-field" v-bind="{ errors }">
                  <v-text-field
                      v-model="valueComputed"
                      outlined
                      dense
                      hide-details="auto"
                      :error-messages="errors"
                      :hint="hint"
                  />
                </slot>
              </validation-provider>
            </slot>
            <div v-if="singleEdit">
              <v-btn color="success" icon outlined @click="singleEditSubmit">
                <v-icon>fa-check</v-icon>
              </v-btn>
              <v-btn icon outlined class="ml-2" @click="editMode = false">
                <v-icon>fa-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <slot name="default">
              <div v-if="value">{{ value }}</div>
              <div v-else class="text--disabled">{{ noValueText }}</div>
            </slot>
            <v-btn v-if="!notEditable && singleEdit" icon color="warning" outlined class="ml-3" @click="editMode = true">
              <v-icon small>fa-edit</v-icon>
            </v-btn>
          </div>
      </v-col>
      <v-col cols="12" v-if="showHRow && $vuetify.breakpoint.smAndDown && !$vuetify.breakpoint.sm" class="my-n3">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {ValidationProvider} from "vee-validate";

export default {
  name: 'FtColInfoView',
  components: { ValidationProvider },
  props: {
    label: String,
    value: String,
    noValueText: {
      type: String,
      default: '-'
    },
    notEditable: {
      type: Boolean,
      default: false
    },
    singleEdit: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    editCallback: Function,
    rules: {
      type: String,
      default: 'required'
    },
    hint: String
  },
  data () {
    return {
      editMode: false,
      showHRow: false,
      tabLayoutParent: null
    }
  },
  computed: {
    valueComputed: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    this.findTabLayout()
    this.registerToTabLayoutCmp()
    this.checkHideHRow()
  },
  methods: {
    checkHideHRow () {
      const p = this.$parent
      const isRow = p.$options._componentTag === 'v-row'
      const lastCol = p.$children[ p.$children.length - 1 ]
      const isLastCol = this === lastCol

      const hideHRow = isRow || isLastCol
      this.showHRow = !hideHRow
    },
    updateEditMode (enabled) {
      this.editMode = this.singleEdit ? false : enabled
    },
    singleEditSubmit () {
      this.editCallback()
      if (this.tabLayoutParent) {
        this.tabLayoutParent.$emit('reload')
      } else {
        this.$emit('reload')
      }
      this.editMode = false
    },
    findTabLayout () {
      let parent = this.$parent
      while (parent && parent.$options._componentTag !== 'ft-card-layout') {
        parent = parent.$parent
      }
      if (parent) {
        this.tabLayoutParent = parent
      }
      // no parent component with tag 'tab-layout' found
    },
    registerToTabLayoutCmp () {
      if (this.notEditable) {
        return
      }

      this.tabLayoutParent?.$on('updateEditMode', this.updateEditMode)
    },
  }
}
</script>

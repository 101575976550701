<script>
import EmailModal from "@/components/modals/clients_customers/EmailModal.vue";
import {ValidationProvider} from "vee-validate";

export default {
  name: "RemoteTopUp",
  components: { ValidationProvider, EmailModal },
  data () {
    return {
      selected: [],
      amountInEuro: null,
      search: '',
      items: []
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('Surname'), value: 'surname' },
        { text: this.$t('Email'), value: 'email' },
      ]
    }
  },
  mounted () {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers () {
      const response = await this.$http.payment.get('/remote-top-ups/visible-users')
      this.items = response.data
    },
    performTopUp () {
      if (this.selected.length === 0) {
        this.$dialog.error({
          title: this.$t('Attention'),
          text: this.$t('Select at least one user'),
        })
        return
      }

      this.$dialog.confirm({
        title: this.$t('Confirm'),
        text: '<p>'
            + this.$t(
                'Do you want to perform the top-up of <b>{0} euros</b> to each one of the following users?',
                [ this.amountInEuro ]
            ) + '<br>'
            + `<small>${this.selected.map(user => `<i>${user.email}</i>`).join(', ')}</small></p>`
            + this.$t(
                "<p>The total amount that will be debited at the end of the month for this top-up is <b>{0} euros</b>.</p>",
                this.amountInEuro * this.selected.length
            ),
        actions: [
          {
            text: this.$t('Cancel')
          },
          {
            text: this.$t('Confirm'),
            handler: this.performTopUpConfirmed
          }
        ],
      })
    },
    async performTopUpConfirmed () {
      await this.$http.payment.post('/remote-top-ups', {
        userIds: this.selected.map(user => user.id),
        amount: this.amountInEuro * 100
      })
    }
  },
}
</script>

<template>
  <div>
    <h1>{{ $t('Remote top up.') }}</h1>
    <v-card color="grey lighten-2" elevation="0" class="mx-4" style="max-width: 500px">
      <v-card-text>
        {{ $t('To proceed to the remote top-up:') }}
        <ol>
          <li>{{ $t('Add desired users by the email they used for the registration on the Apollo app.') }}</li>
          <li>{{ $t('Select the users to whom you want to make the top-up.') }}</li>
          <li>{{ $t('Press the top up button.') }}</li>
        </ol>

      </v-card-text>
    </v-card>

    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>{{ $t('Users') }}</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <email-modal @close="fetchUsers"/>
        </v-toolbar>
        <v-text-field
            v-model="search"
            outlined
            class="mx-10"
            :label="$t('Search users...')"
            dense
        />
      </template>
    </v-data-table>

    <v-card outlined class="mx-auto" style="max-width: 400px">
      <v-card-text>
        <div class="text-body-1 mb-2">{{ $t('You selected {0} users', [selected.length]) }}</div>

        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
              rules="required|min_value:5|max_value:100"
              :name="$t('Price')"
              v-slot="{ errors }"
          >
            <slot v-bind="{ errors }">
              <v-text-field
                  v-model.number="amountInEuro"
                  :error-messages="errors"
                  type="number"
                  prefix="€"
                  outlined
              >
              </v-text-field>
            </slot>
          </validation-provider>

          <div class="text-right">
            <v-btn color="success" @click="handleSubmit(performTopUp)">
              {{ $t('Top up') }}
            </v-btn>
          </div>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

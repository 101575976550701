<script>
export default {
  name: "ClientCustomerInfo",
  data () {
    return {
      bankAccounts: [],
      defaultPM: null,

      addLoading: false
    }
  },
  mounted () {
    this.loadBankAccounts()
  },
  methods: {
    async loadBankAccounts () {
      const res = await this.$http.payment.get('/client-customers/stripe-payment-methods')
      this.bankAccounts = res.data.bankAccounts
      this.defaultPM = res.data.defaultPM
    },
    async setDefault (item) {
      this.defaultPM = item.id
      await this.$http.payment.patch('/client-customers/stripe-payment-methods', {
        defaultPM: item.id
      })
      await this.loadBankAccounts()
    },
    async addMethod() {
      try {
        this.addLoading = true
        const res = await this.$http.payment.post('/client-customers/stripe-payment-methods', {
          returnUrl: location.href
        })

        window.location = res.data;
      } catch (e) {
        this.addLoading = false
      }
    },
    async deleteMethodPrompt(item) {
      const res = await this.$dialog.confirm({
        title: this.$t('Confirm deletion'),
        text: this.$t('Do you confirm you want to delete the payment method that ends with <b>{0}</b>?', [item.last4])
      })
      if (res) await this.deleteMethod(item)
    },
    async deleteMethod(item) {
      try {
        item.deleteLoading = true
        await this.$http.payment.delete('/client-customers/stripe-payment-methods?id=' + item.id)
        await this.loadBankAccounts()

        if (this.bankAccounts.length === 0) {
          this.$store.commit('setCCRegComplete', false)
          await this.$router.push('/')
        }
      } catch (e) {
        item.deleteLoading = false
      }
    },
  },
}
</script>

<template>
  <div>
    <h1>{{ $t('Account information') }}</h1>

    <v-card outlined class="my-6">
      <v-card-title>
        {{ $t('Bank accounts added') }}

        <v-spacer/>
        <v-btn color="success" outlined @click="addMethod" :loading="addLoading" :disabled="addLoading">
          {{ $t('Add') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
              v-for="(item, i) in bankAccounts"
              :key="i"
          >
            <v-list-item-content>
              <v-list-item-title>
                <b>
                  <span class="mr-1">••••</span>
                  {{ item.last4 }}
                </b>

                <span class="mx-3">-</span>

                {{item.billingName}}

                <span class="mx-3">-</span>

                <small class="mr-1">{{ $t('Bank code:') }}</small>
                {{item.bankCode}}

                <span class="mx-3">-</span>

                <small class="mr-1">{{ $t('Branch code:') }}</small>
                {{item.branchCode}}

                <v-chip color="primary"
                        class="ml-2"
                        v-if="item.id === defaultPM"
                        small
                >
                  {{ $t('Default') }}
                </v-chip>
                <v-chip color="primary"
                        class="ml-2"
                        v-else
                        x-small outlined
                        @click="setDefault(item)"
                >
                  {{ $t('Set as default') }}
                </v-chip>

                <v-btn class="ml-2"
                       color="red"
                       icon x-small
                       @click="deleteMethodPrompt(item)"
                       :loading="item.deleteLoading"
                       :disabled="item.deleteLoading"
                       v-if="item.id !== defaultPM || bankAccounts.length === 1">
                  <v-icon>fa-trash</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
